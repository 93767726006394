<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-nav-icon
        @click="toggleMini = !toggleMini"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="padding-top: 17px; margin-right: 15px">
        <v-switch
          v-model="themeSwitch"
          @change="switchTheme"
          inset
          :label="themeSwitch ? 'Dark' : 'Light'"
        ></v-switch>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="sidebarMenu"
      permanent
      app
      :mini-variant.sync="mini"
      :color="theme ? 'grey darken-4' : ''"
    >
      <v-list-item
        class="px-2 pb-0"
        style="display: flex; flex-direction: column;"
      >
        <v-col>
          <v-row class="pt-3" @click="showCompanies = !showCompanies">
            <v-list-item-avatar>
              <v-img
                alt=""
                :src="
                  getActiveCompany
                    ? getActiveCompany.companyAvator
                    : getMyProfile.imageURL
                "
              />
            </v-list-item-avatar>
            <v-list-item-content class="text-truncate">
              {{
                getActiveCompany
                  ? getActiveCompany.companyName
                  : "SELECT COMPANY"
              }}
            </v-list-item-content>

            <div class="pt-4">
              <v-btn icon small>
                <v-icon v-if="showCompanies">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>

          <!-- display companies if available -->
          <v-row
            v-if="getMyCompanies.length > 0"
            class="px-2"
            lg="12"
            :style="`display: ${showCompanies ? 'block' : 'none'} `"
          >
            <v-col
              class="pt-3"
              v-for="company in getMyCompanies"
              :key="company.id"
            >
              <v-row class="pt-3" @click="submitActiveCompany(company)">
                <v-list-item-avatar>
                  <v-img alt="" :src="company.companyAvator" />
                </v-list-item-avatar>
                <v-list-item-content class="text-truncate">
                  {{ company.companyName }}
                </v-list-item-content>

                <div class="pt-4">
                  <v-btn icon small>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-col>
          </v-row>

          <!-- Ask user to register a company if none available -->
          <v-row
            v-else
            class="px-2"
            lg="12"
            :style="`display: ${showCompanies ? 'block' : 'none'} `"
          >
            <v-col class="pt-3">
              <v-btn lg="12" @click="registerCompany" variant="success"
                >Register Company</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-list-item>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.href"
          style="text-decoration: none"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container class="px-4 py-12 fill-height" fluid>
        <v-row class="fill-height">
          <v-col>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
      <v-footer app class="py-3" elevation="2">
        <span class="ml-auto overline">Group NGS &copy;2021</span>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
const imageLink = "../../../assets/logo.png";
export default {
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    ...mapState(["myProfile", "myCompanies", "activeCompany"]),
    ...mapGetters(["getMyProfile", "getMyCompanies", "getActiveCompany"]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$store.dispatch("fetchMyProfile");
        this.$store.dispatch("fetchMyCompanies");
        this.$store.dispatch("fetchMyCompany");
      }
    },

    getActiveCompany(val) {
      this.$store.dispatch("fetchMyProfile");
      this.$store.dispatch("fetchMyCompanies");
      this.$store.dispatch("fetchMyCompany");
      this.$forceUpdate();
    },
  },

  data: () => ({
    showCompanies: false,
    sidebarMenu: true,
    toggleMini: false,
    themeSwitch: false,
    imageLink: imageLink,
    theme: false,
    items: [
      {
        title: "Home",
        href: "/home",
        icon: "mdi-home-outline",
      },
      {
        title: "Wallet",
        href: "/wallet",
        icon: "mdi mdi-wallet-plus",
      },
      {
        title: "Requests",
        href: "/requests",
        icon: "mdi-palette-swatch",
      },
      {
        title: "API Credentials",
        href: "/credentials",
        icon: "mdi-key",
      },
      {
        title: "Settings",
        href: "/settings",
        icon: "mdi-cog",
      },
      {
        title: "Api Documentation",
        href: "/apiDocumentation",
        icon: "mdi-palette-swatch",
      },
      {
        title: "Community",
        href: "/community",
        icon: "mdi-comment-multiple-outline",
      },
      {
        title: "Logout",
        href: "/login",
        icon: "mdi-key-outline",
      },
    ],
  }),

  beforeCreate() {
    this.$store.dispatch("fetchMyProfile");
    this.$store.dispatch("fetchMyCompanies");
    this.$store.dispatch("fetchMyCompany");
  },

  mounted() {
    // let theme = ;
    if (localStorage.getItem("theme") == "light") {
      this.themeSwitch = false;
      this.theme = false;
    } else {
      this.themeSwitch = true;
      this.theme = true;
    }
  },
  methods: {
    submitActiveCompany(company) {
      this.$store.dispatch("submitActiveCompany", company);
      this.showCompanies = false;
      this.$router.push({
        path: "/",
        query: {},
      });
    },

    registerCompany() {
      this.$router.push({
        path: "/createCompany",
        query: {},
      });
    },

    switchTheme(e) {
      if (e) {
        localStorage.setItem("theme", "dark");
      } else {
        localStorage.setItem("theme", "light");
      }

      console.log("THEME:::", e);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
</style>
